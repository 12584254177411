@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* The rest of this file Follows ITCSS methodology by using INUIT CSS FRAMEWORK
* https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
* https://github.com/inuitcss/inuitcss
 */

/* ==========================================================================
   # GLOBAL
   ========================================================================== */

body,
html {
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
  Widget Iframe should be 100%
 **/

iframe {
  width: 100%;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none !important;
}

/* ==========================================================================
   # FADEIN OBJECT
   ========================================================================== */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.o-fade-in {
  animation: fadein 0.3s;
}

.o-fade-in__long {
  animation: fadein 0.6s;
}

/* ==========================================================================
   # PHONE OBJECT
   ========================================================================== */

.o-phone-img-wrap {
  width: min(46vh, 56vw);
  position: relative;
  min-width: 320px;
  max-width: 440px;
}

.o-phone-img-wrap__iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15% 7% 14%;
}

/* ==========================================================================
   # DEVICE OBJECT
   ========================================================================== */

.o-device-wrap {
  width: 17vh;
  min-width: 100px;
  max-width: 132px;
  margin-bottom: 1vh;
  margin-top: 1vh;
}

/* ==========================================================================
   # RADIO COMPONENT

   Used in delivery options
   ========================================================================== */

.c-radio-segment__input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-radio-segment__input > input + label {
  display: block;
  border: 1px solid black;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.c-radio-segment__input > input + label > i {
  opacity: 0;
  display: block;
  transition: opacity 400ms;
  content: "";
  border-radius: 100%;
  background-color: black;
  height: 16px;
  width: 16px;
  margin: 2px 0 0 2px;
}

.c-radio-segment__input > input:checked + label > i {
  opacity: 1;
}

/* Reused radio component (.c-radio-segment__input) but needed slightly different styling for shipping method launch experience test site */
.shipping-method-test-c-radio-segment__input {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 14px;
  align-items: flex-start;
}

.shipping-method-test-c-radio-segment__input > input + label {
  display: block;
  border: 1px solid black;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.shipping-method-test-c-radio-segment__input > input + label > i {
  opacity: 0;
  display: block;
  transition: opacity 400ms;
  content: "";
  border-radius: 100%;
  background-color: black;
  height: 16px;
  width: 16px;
  margin: 2px 0 0 2px;
}

.shipping-method-test-c-radio-segment__input > input:checked + label > i {
  opacity: 1;
}

/* ==========================================================================
   # RADIO COMPONENT

   Used in billing
   ========================================================================== */

.c-floating-input > input {
  @apply pt-8;
}

.c-floating-input > input ~ label {
  @apply opacity-75 scale-75 -translate-y-3 translate-x-1;
}

/* ==========================================================================
   # CONFIGURATOR COMPONENT
   ========================================================================== */

.c-configurator {
  font-family: "Montserrat", sans-serif;
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 250px;
  grid-template-rows: max(50px, 8%) 1fr;
  grid-template-areas:
    "switcher config"
    "checkout config";
}

@media (min-width: 900px) {
  .c-configurator {
    grid-template-columns: 1fr 350px;
  }
}

.c-configurator__switcher {
  grid-area: switcher;
}

.c-configurator__config {
  grid-area: config;
}

.c-configurator__options {
  min-height: 5rem;
}

.c-configurator__checkout {
  grid-area: checkout;
}

/* ==========================================================================
   # LANDING COMPONENT
   ========================================================================== */

.bg-color-outer-space {
  background: #253238;
}

.u-select-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round'  stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

/* ==========================================================================
   # BASKET COMPONENT
   ========================================================================== */

@media (max-width: 768px) {
  .mobile-basket-hide-order {
    display: none;
  }
}

/* ==========================================================================
   # WIDGET STYLE
   ========================================================================== */

.demo hb-launch-home::part(container),
.demo hb-launch-search::part(container) {
  border-color: transparent;
}

.demo hb-launch-home::part(select-button),
.demo hb-launch-search::part(search-button),
.demo .cy-continue-delivery:enabled,
.demo .cy-continue-billing:enabled,
.demo .cy-complete-order:enabled {
  background-color: #3a3a3a;
}

/* ==========================================================================
   # PICKUP CONFIRMATION
   ========================================================================== */
.pickup-confirmation::part(container) {
  display: inline-block;
  width: 100%;
  font-family: "Futura PT", "Futura Book", Tahoma, Geneva, Verdana, Arial, sans-serif;
}
.pickup-confirmation::part(title) {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: 2rem;
}
.pickup-confirmation::part(subtitle) {
  padding-bottom: 1rem;
  font-size: 0.9rem;
  font-style: italic;
}

@media (min-width: 768px) {
  .pickup-confirmation::part(collect-point-wrapper) {
    display: grid;
    grid-gap: 0 1.2rem;
    grid-template-columns: 2fr 2fr 3fr;
    grid-template-rows: auto auto;
    padding-top: 1rem;
  }
}

.pickup-confirmation::part(address) {
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 2;
}
.pickup-confirmation::part(find-collect-point-button) {
  display: none;
}
.pickup-confirmation::part(opening-times) {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: max-content;
  grid-gap: 0 1rem;
  grid-column: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 0.8rem;
  margin-bottom: 8px;
}
.pickup-confirmation::part(map) {
  grid-column: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.pickup-confirmation::part(distance) {
  justify-self: end;
}
